<template lang="pug">
AppLayout.t-webview(app-bar-color="transparent")
  template(v-slot:header) Taplink
  p List
</template>
<script>
// import { mapGetters, mapActions } from 'vuex'
import AppLayout from 'T@/AppLayout.vue'
// import TaxPassport from './Taxpassport.vue'

export default {
  components: {
    AppLayout,
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {},
}
</script>
<style lang="stylus"></style>
